import React from 'react';
import { Helmet } from 'react-helmet';

import { OpeningsWrapper } from './style';
import Button from '../Buttons';

const Openings = () => {
  return (
    <OpeningsWrapper>
      <Helmet>
        <script
          src="https://optimumfuturist.bamboohr.com/js/embed.js"
          type="text/javascript"
          async
          defer
        ></script>
      </Helmet>
      <div className="container">
        <div
          id="BambooHR"
          data-domain="optimumfuturist.bamboohr.com"
          data-version="1.0.0"
          data-departmentId=""
        ></div>
        <div className="d-flex justify-content-center mt-5">
          <Button
            as="a"
            btnText="See All Openings"
            target="_blank"
            btnType="rounded-outline"
            href="https://optimumfuturist.bamboohr.com/jobs/"
          />
        </div>
      </div>
    </OpeningsWrapper>
  );
};

export default Openings;
