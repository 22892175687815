import styled from 'styled-components';
import { Wrapper } from '../../layout/global-style';

export const OpeningsWrapper = styled(Wrapper)`
  position: relative;
  padding: 8rem 0 18rem;

  .row {
    justify-content: center;
  }

  .col {
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .BambooHR-ATS-board {
    h2 {
      font-size: 2.6rem;
      color: white;
      border-bottom: 0 !important;

      &::before {
        content: '';
        display: block;
        height: 0.25rem;
        width: 3rem;
        border-radius: 0.4rem;
        background: linear-gradient(180deg, #f4d293, #cda04b);
        margin-bottom: 1.6rem;
      }
    }
  }
  .BambooHR-ATS-Department-Header {
    display: none;
  }

  .BambooHR-ATS-Jobs-List {
    padding: 2rem 0 !important;
    border-bottom-color: #383f64 !important;
  }

  .BambooHR-ATS-Location {
    color: white;
    opacity: 0.8;
    font-style: italic;
  }

  .BambooHR-ATS-board li {
    padding: 0 0 0.5rem;
  }
`;
