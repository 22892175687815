import React from 'react';
import PropTypes from 'prop-types';

import { FeaturesWrapper, Card } from './style';

const FeatureCard = (props) => {
  return (
    <Card>
      <img src={props.img} alt={`icon representing ${props.title}`} />
      <div>
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
      </div>
    </Card>
  );
};

const index = () => {
  const companyFeatures = [
    {
      id: 1,
      img: '/img/icons/icon-fastgrowing.svg',
      title: 'Fast-Growing and Dynamic',
      desc: 'Optimum Futurist has grown explosively in just the last two years. What started off as a small partnership now employs a robust team of professionals across two continents, and we have no plans to slow down. Our employees get to work in an exciting, fast-paced growth environment.',
    },
    {
      id: 2,
      img: '/img/icons/icon-greatcolleagues.svg',
      title: 'Fostering Intrapreneurship',
      desc: "People can only perform their best when they're given ample room to do so. We believe in providing every employee with freedom and autonomy, and our approach to team management means flexible scheduling and the opportunity to self-manage through intrapreneurship.",
    },
    {
      id: 3,
      img: '/img/icons/icon-takechange.svg',
      title: 'Upward Mobility',
      desc: 'Many development agencies and IT consulting firms place a hard ceiling on growth and compensation, leaving many talented employees elsewhere. Our team respects and affirms the importance of experience by providing a great payment structure and strong internal upward mobility.',
    },
    {
      id: 4,
      img: '/img/icons/icon-learning.svg',
      title: 'In-House Education',
      desc: 'While we expect each prospective employee to come with solid core development skills, we provide high-quality state-of-the-art education on specific technology stacks as required. In addition to an exciting work environment, you also get a nurturing and educational one.',
    },
    {
      id: 5,
      img: '/img/icons/icon-techstacks.svg',
      title: 'Latest Technology Stacks',
      desc: "Our team is at the cutting edge of development and design, and we're constantly researching new and novel ways to improve our efficiency and production workflow. Rather than deal with outdated technologies, we empower our employees with the latest technology stacks and techniques.",
    },
    {
      id: 6,
      img: '/img/icons/icon-crossdomain.svg',
      title: 'Cross-Domain Exposure',
      desc: "Our customers span from small to large, and we have clients from North America to Asia and beyond. Working at Optimum Futurist means you're guaranteed exposure to a wide variety of niches, domains, languages, and challenges, each of which grows your skillset and your worldview.",
    },
  ];

  return (
    <FeaturesWrapper>
      <div className="container">
        <h2 className="sr-only">Optimum Futurist Features </h2>
        <div className="feature-grid">
          {companyFeatures.map(({ id, img, title, desc }) => (
            <FeatureCard key={id} img={img} title={title} desc={desc} />
          ))}
        </div>
      </div>
    </FeaturesWrapper>
  );
};

FeatureCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default index;
