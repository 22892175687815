import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const FeaturesWrapper = styled(Wrapper)`
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }

  h3 {
    font-size: 2rem;
    color: ${colors.cadetBlue};
    margin: 2rem 0;
  }

  @media ${device.laptop} {
    .feature-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media ${device.desktopS} {
    padding-bottom: 15rem;
  }
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  & img {
    width: 80px;
    height: 80px;
  }

  & h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${colors.cadetBlue};
    margin-top: 1.2rem;
    margin-bottom: 0.4rem;
  }

  p {
    color: ${colors.lightGrey};
    font-size: 1rem;
  }

  @media ${device.tablet} {
    padding: 0 1.25rem;
  }
`;
