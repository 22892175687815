import React from 'react';

import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';

import HeroDoubleImg from '../components/HeroDoubleImg';
import CompanyFeatures from '../components/CompanyFeatures';
import Openings from '../components/Openings';

const career = () => {
  return (
    <Layout pageTitle="Why Join Us | Career">
      <SEO title={ROUTES.career.title} description={ROUTES.career.desc} />
      <HeroDoubleImg pageTitle="Why Join Us">
        <div className="img-group">
          <img
            src="/img/optimum/Optimum-Futurist-Programming-Career.jpg"
            alt="women employee coding on laptop"
          />
          <img
            src="/img/optimum/Optimum-Futurist-Career-contract-signing.jpg"
            alt="Optimum Futurist new employee contract signing"
          />
        </div>
        <p>
          Since Optimum Futurist began, our group efforts have been devoted to
          helping small to enterprise-level organizations achieve their digital
          goals. To do so effectively, we&apos;ve built a company culture that
          fosters creativity, provides a healthy work/life balance and believes
          in giving back to the community.
        </p>
      </HeroDoubleImg>
      <Openings />
      <CompanyFeatures />
    </Layout>
  );
};

export default career;
